import React from "react"
import Container from "../UI/Container"

// import { Link } from "react-router-dom"

import CelecLogo from "../../assets/logos/Celec_Logo_Blue.svg"

const Landing = () => {
  return (
    <>
      <Container className="text-center max-w-3xl flex flex-col justify-center items-center h-[calc(100vh-80px)] tab:h-[100vh]">
        <img
          className="w-48 hidden ph:block"
          src={CelecLogo}
          alt="Celec Logo"
        />
        <h1 className="font-bold uppercase text-5xl text-Color-Dark-Blue mb-5 ph:hidden">
          Celec Club <span className="text-Color-Blue">.</span>
        </h1>
        <p className="font-bold text-lg text-[#788092] mb-5 tracking-[3px] tab:max-w-lg">
          The Club of Electrical Engineering's Faculty at The University of
          Science and Technology Houari Boumediene
        </p>
        <div className="uppercase w-96 ph:w-80 bg-white rounded-lg mx-auto flex justify-between items-center px-10 py-2">
          <h3 className="font-bold text-Color-Dark-Blue">Join Our Blog</h3>
          <a
            href="https://blog.celec.club"
            className="px-6 py-2 bg-Color-Blue text-white rounded-lg"
          >
            Here
          </a>
        </div>
      </Container>
    </>
  )
}

export default Landing
