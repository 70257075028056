import React from "react"

import PagesLanding from "../components/UI/PagesLanding"
import RegisterForm from "../components/forms/RegisterForm/RegisterForm"

import CelecLogo from "../assets/logos/Celec_Logo_Blue.svg"

const Register = () => {
  return (
    <div>
      <PagesLanding
        landingHeading="Register Now to join the club"
        landingText=""
      >
        <img src={CelecLogo} alt="Celec Club Logo" className="w-28" />
      </PagesLanding>
      <RegisterForm />
    </div>
  )
}

export default Register
