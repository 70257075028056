import React from "react"

import Container from "../UI/Container"
import ContentBox from "../UI/ContentBox"

import travelMap from "../../assets/icons/travel-map.png"
import mail from "../../assets/icons/mail.png"
import smartphone from "../../assets/icons/smartphone.png"

// import {
//   faMapLocationDot,
//   faMobileScreen,
//   faEnvelope,
// } from "@fortawesome/free-solid-svg-icons"

const clubInformation = [
  {
    src: travelMap,
    heading: "Our Location",
    paragraph: "USTHB, Electrical Engineering's Faculty, Lab 13",
  },
  {
    src: mail,
    heading: "Our E-mail Address",
    paragraph: "contact@celec.club",
  },
  {
    src: smartphone,
    heading: "Our Phone Number",
    paragraph: "0656 80 04 20",
  },
]

const imgClasses = "mb-6 w-20 text-Color-Dark-Blue relative z-10"
const headingClasses = "mb-1 text-2xl tab:text-xl font-bold uppercase"
const parClasses = "text-lg tab:text-sm text-Color-Black"

const ClubInfo = () => {
  return (
    <Container className="bg-white grid grid-cols-3 ph:grid-cols-1 mb-[100px]">
      {clubInformation.map((info, infoIndex) => (
        <ContentBox
          key={infoIndex}
          src={info.src}
          imgClasses={imgClasses}
          heading={info.heading}
          headingClasses={headingClasses}
          paragraph={info.paragraph}
          parClasses={parClasses}
        />
      ))}
    </Container>
  )
}

export default ClubInfo
