import { useState } from "react"

import axios from "axios"
import { Form, Formik } from "formik"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

import Container from "../../UI/Container"
import Input from "../formComponents/Input"
import Select from "../formComponents/Select"
import RadioInput from "../formComponents/RadioInput"
import TextInput from "../formComponents/TextInput"
import Button from "../../UI/Button"
import FormPortal from "../FormProtal/FormPortal"

import { trainingSchema } from "../formSchema/trainingSchema"

// const loader = (
//   <svg
//     className="inline-block ml-3"
//     width="20"
//     height="20"
//     viewBox="0 0 38 38"
//     xmlns="http://www.w3.org/2000/svg"
//     stroke="#fff"
//   >
//     <g fill="none" fill-rule="evenodd">
//       <g transform="translate(1 1)" stroke-width="3">
//         <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
//         <path d="M36 18c0-9.94-8.06-18-18-18">
//           <animateTransform
//             attributeName="transform"
//             type="rotate"
//             from="0 18 18"
//             to="360 18 18"
//             dur="1s"
//             repeatCount="indefinite"
//           />
//         </path>
//       </g>
//     </g>
//   </svg>
// )

const initialValues = {
  fullname: "",
  email: "",
  registration_number: "",
  phone: "",
  is_celec_memeber: "",
  study_level: "",
  study_field: "",
  course_goals: "",
}

let errorMessage = ""

const TrainingForm = (props) => {
  const [successRequest, setSuccessRequest] = useState(false)
  const [showPortal, setShowPortal] = useState(false)
  const [formIsNotValid, setFormIsNotValid] = useState(false)

  const closePortalHandler = () => {
    setShowPortal(false)
    setFormIsNotValid(false)
  }

  const registerToCourseRequestURL = `https://api.celec.club/api/trainings/${props.courseId}/register`

  const formData = new FormData()

  const submitFormHandler = (values, { resetForm }) => {
    closePortalHandler()
    setSuccessRequest(false)

    errorMessage = ""

    function submitMemberData(memberValues) {
      formData.append("fullname", memberValues.fullname)
      formData.append("email", memberValues.email)
      formData.append("is_celec_memeber", memberValues.is_celec_memeber)
      formData.append("registration_number", memberValues.registration_number)
      formData.append("study_level", memberValues.study_level)
      formData.append("study_field", memberValues.study_field)
      formData.append("course_goals", memberValues.course_goals)
      formData.append("phone", memberValues.phone)

      axios
        .post(registerToCourseRequestURL, formData)
        .then(function (response) {
          // const fullName = response.data.fullname
          // alert("Congratulations " + fullName)
          setSuccessRequest(true)

          resetForm({ values: "" })
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            const data = error.response.data
            const errors = data.errors
            for (const [key, value] of Object.entries(errors)) {
              errorMessage += `${key}: ${value}`
              errorMessage += "\n"
            }
            // alert(errorMessage)
            setFormIsNotValid(true)
            setShowPortal(true)
          } else {
            // errorMessage = "Server error please try again"
            alert("Server error please try again")
          }
        })
    }
    submitMemberData(values)
  }

  return (
    <Container className="my-[100px]">
      <FormPortal
        isOpened={showPortal}
        formValidity={formIsNotValid}
        errorMessage={errorMessage}
        success={false}
        closePortal={closePortalHandler}
      ></FormPortal>
      <Formik
        initialValues={initialValues}
        validationSchema={trainingSchema}
        onSubmit={submitFormHandler}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-2 justify-center relative ph:grid-cols-1">
            <div className="justify-self-center">
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                name="fullname"
              />
              <Input id="email" label="Email" type="email" name="email" />
              <p className="text-Color-Blue ml-8 mb-3">
                Are you a member of Celec Club
              </p>
              <div>
                <RadioInput
                  id="celec_member-true"
                  label="Yes"
                  name="is_celec_memeber"
                  value="yes"
                />
                <RadioInput
                  id="celec_member-false"
                  label="No"
                  name="is_celec_memeber"
                  value="no"
                />
              </div>
              <Input
                id="study_field"
                label="Study Field"
                type="text"
                name="study_field"
              />
              <Select
                id="study_level"
                label="Study Level"
                type="text"
                name="study_level"
              >
                <option value="">Please select your study level</option>
                <option value="L1">L1</option>
                <option value="L2">L2</option>
                <option value="L3">L3</option>
                <option value="M1">M1</option>
                <option value="M2">M2</option>
                <option value="phd">Doctorate</option>
              </Select>
            </div>
            <div className="justify-self-center">
              <Input
                id="registration_number"
                label="Badge Number"
                type="number"
                name="registration_number"
                min="0"
              />
              <Input
                id="phone"
                label="Phone number"
                type="number"
                name="phone"
                min="0"
              />
              <TextInput
                id="course_goals"
                label="What are your goals for that course"
                name="course_goals"
              />
              <Button
                type="submit"
                // className="w-80 text-white bg-Color-Blue py-3 rounded-lg mt-2"
                disabled={props.disableBtn}
                className={
                  props.closed
                    ? "w-80 text-white bg-black py-3 rounded-lg mt-2"
                    : "w-80 text-white bg-Color-Blue py-3 rounded-lg mt-2"
                }
              >
                {props.closed ? "Registration are closed" : "Submit"}
                {successRequest && (
                  <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default TrainingForm
