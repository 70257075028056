import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Header from "./layouts/Header/Header"
import Footer from "./layouts/Footer/Footer"

import Home from "./pages/Home"
import Register from "./pages/Register"
import Training from "./pages/Training"
import SingleCourse from "./pages/SingleCourse"
import Trainer from "./pages/Trainer"
import Events from "./pages/Events"
import EventRegistration from "./components/CelecEvent/EventRegistration"

import { EventsContextProvider } from "../src/utils/event-context"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="register" element={<Register />} />
          <Route path="training" element={<Training />} />
          <Route path="training/:courseId" element={<SingleCourse />} />
          <Route path="Trainer" element={<Trainer />} />
          <Route
            path="event"
            element={
              <EventsContextProvider>
                <Events />
              </EventsContextProvider>
            }
          />
          <Route
            path="event/:eventId"
            element={
              <EventsContextProvider>
                <EventRegistration />
              </EventsContextProvider>
            }
          />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
