import React from "react"

import Container from "../../components/UI/Container"
import SocialMedia from "./SocialMedia"
import FooterForm from "./FooterForm"

import CelecLogo from "../../assets/logos/Celec_Logo_Blue.svg"
import AdexLogo from "../../assets/logos/Adex_Logo.png"

const Footer = () => {
  return (
    <Container
      id="footer"
      className="bg-Color-Gray py-10 grid grid-cols-2 ph:grid-cols-1 gap-y-3"
    >
      <div className="self-end ">
        <div className="flex">
          <img src={CelecLogo} alt="Celec Club Logo" className="w-20 mb-4" />
          <img src={AdexLogo} alt="Celec Club Logo" className="h-20 mb-4" />
        </div>
        <p className="max-w-[300px] text-justify mb-8">
          The Club of Electrical Engineering's Faculty at The University of
          Science and Technology Houari Boumediene
        </p>
        <SocialMedia />
      </div>
      <div className="justify-self-center ">
        <h2 className="text-4xl font-bold uppercase text-Color-Dark-Blue mb-5">
          Contact Us
        </h2>
        <FooterForm />
        <div className="flex mt-12">
          <div className="mr-10">
            <h3 className="text-lg font-bold uppercase mb-5">Location</h3>
            <p className="self-center">USTHB, FGE, LABO 13</p>
          </div>
          <div>
            <h3 className="text-lg font-bold uppercase mb-5">Contact</h3>
            <p>0656 80 04 20</p>
	  {/*<p>0556 94 85 20</p>
            <p>0792 93 28 05</p>*/}
	    <p>contact@celec.club</p>
	  {/*<p>usthb.celec@gmail.com</p>*/}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Footer
