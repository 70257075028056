import * as yup from "yup"

export const trainingSchema = yup.object().shape({
  fullname: yup.string().required(),

  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter you email"),

  is_celec_memeber: yup.string().required().oneOf(["yes", "no"]),
  registration_number: yup.number().positive().integer().required(),
  study_field: yup.string().required(),

  phone: yup.number().positive().integer().required(),

  study_level: yup
    .string()
    .oneOf(["L1", "L2", "L3", "M1", "M2", "phd"], "Invalid level")
    .required("Required"),

  course_goals: yup.string().required(),
})
