import React, { useEffect, useState } from "react"
import axios from "axios"

const EventsContext = React.createContext({
  events: [],
})

export const EventsContextProvider = (props) => {
  const [events, setEvents] = useState([])

  const apiUrl = "https://api.celec.club/api/events/all"

  useEffect(() => {
    const fetchedEvent = []

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.data
        for (const key in data) {
          fetchedEvent.push({
            id: data[key].id,
            title: data[key].name,
            description: data[key].description,
            imgSrc: `https://api.celec.club/storage/${data[key].image}`,
            location: data[key].location,
            isClosed: data[key].is_closed,
          })
        }
        console.log(fetchedEvent)
        setEvents(fetchedEvent)
      })
      .catch((error) => {
        // console.log(error)
      })
  }, [])

  return (
    <EventsContext.Provider value={{ events }}>
      {props.children}
    </EventsContext.Provider>
  )
}

export default EventsContext
