import React from "react"
import { NavHashLink } from "react-router-hash-link"

import PagesLanding from "../components/UI/PagesLanding"
import Courses from "../components/Courses/Courses"

import { CoursesContextProvider } from "../utils/courses-context"

const Training = () => {
  return (
    <div>
      <PagesLanding
        landingHeading="Sharpen your skills"
        landingText="Learn a new skill, with the best trainings"
      >
        <NavHashLink
          to="/training#trainings"
          className="bg-Color-Blue text-white text-lg uppercase p-4 my-10 rounded-lg"
        >
          Choose a training
        </NavHashLink>
      </PagesLanding>
      <CoursesContextProvider>
        <Courses />
      </CoursesContextProvider>
    </div>
  )
}

export default Training
