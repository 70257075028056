import React from "react"

const ContentBox = (props) => {
  return (
    <div className={`p-10 relative ${props.className}`}>
      <img src={props.src} alt="" className={props.imgClasses} />
      <div>
        <h3 className={props.headingClasses}>{props.heading}</h3>
        <p className={props.parClasses}>{props.paragraph}</p>
      </div>
      {props.children}
    </div>
  )
}

export default ContentBox
