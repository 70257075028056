import React, { useEffect, useState } from "react"
import axios from "axios"

const BlogContext = React.createContext({
  blogData: [],
})

export const BlogContextProvider = (props) => {
  const [blogData, setBlogData] = useState([])

  const apiUrl = "https://blog.celec.club/api/blogs/all"

  useEffect(() => {
    const fetchedData = []

    axios
      .get(apiUrl)
      .then(function (response) {
        // handle success
        const data = response.data
        for (const key in data) {
          fetchedData.push({
            id: key,
            content: data[key],
          })
        }
        setBlogData(fetchedData)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }, [])

  return (
    <BlogContext.Provider value={{ blogData }}>
      {props.children}
    </BlogContext.Provider>
  )
}

export default BlogContext
