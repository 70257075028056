import React from "react"

import Container from "./Container"
import Banner from "./Banner"

const PagesLanding = (props) => {
  return (
    <Container className="landing-container">
      <h2>{props.landingHeading}</h2>
      <span></span>
      <p>{props.landingText}</p>
      {props.children}
      <Banner className="absolute bottom-0" />
    </Container>
  )
}

export default PagesLanding
