import React, { useEffect, useState } from "react"
import axios from "axios"

const CoursesContext = React.createContext({
  courses: [],
})

export const CoursesContextProvider = (props) => {
  const [courses, setCourses] = useState([])

  const apiUrl = "https://api.celec.club/api/trainings/get"

  useEffect(() => {
    const fetchedCourses = []

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.data
        for (const key in data) {
          fetchedCourses.push({
            id: data[key].id,
            title: data[key].title,
            description: data[key].description,
            imgSrc: `https://api.celec.club/storage/${data[key].cover}`,
            slug: data[key].slug,
            isClosed: data[key].is_closed,
          })
        }
        console.log(fetchedCourses)
        setCourses(fetchedCourses)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <CoursesContext.Provider value={{ courses }}>
      {props.children}
    </CoursesContext.Provider>
  )
}

export default CoursesContext
