import { useRef, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faCheckCircle } from "@fortawesome/free-regular-svg-icons"

import axios from "axios"

const loader = (
  <svg
    className="inline-block ml-3"
    width="20"
    height="20"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="3">
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
)

const FooterForm = () => {
  const emailRef = useRef("")
  const messageRef = useRef("")

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successRequest, setSuccessRequest] = useState(false)

  const handleSubmitMessage = (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    setSuccessRequest(false)

    const messageInformation = {
      email: emailRef.current.value,
      message: messageRef.current.value,
    }

    axios
      .post("https://api.celec.club/api/contact/create", messageInformation)
      .then(function (response) {
        setIsSubmitting(false)
        setSuccessRequest(true)

        emailRef.current.value = ""
        messageRef.current.value = ""
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <form onSubmit={handleSubmitMessage}>
      <div className="relative mb-2">
        <input
          ref={emailRef}
          type="email"
          id="contact-email"
          name="email"
          required
          placeholder=" "
          className="h-8 w-[18.75rem] border-[1px] border-Color-Blue border-solid rounded-md p-3 text-xs outline-0 bg-transparent form-input"
        />
        <label
          htmlFor="contact-email"
          className="absolute left-3 top-[50%] translate-y-[-50%] text-xs text-Color-Blue"
        >
          <FontAwesomeIcon icon={faEnvelope} className="mr-2.5" />
          Enter your e-mail address
        </label>
      </div>
      <div className="relative">
        <textarea
          ref={messageRef}
          name="message"
          id="message"
          required
          placeholder=" "
          className="w-[18.75rem] border-[1px] border-Color-Blue border-solid rounded-md p-3 text-xs outline-0 bg-transparent form-input"
        ></textarea>
        <label
          htmlFor="message"
          className="absolute left-3 top-5 translate-y-[-50%] text-xs text-Color-Blue"
        >
          Write here
        </label>
      </div>
      <button
        type="submit"
        value="Submit"
        className="w-[18.75rem] h-8 rounded-md text-white bg-Color-Blue"
      >
        Submit
        {isSubmitting && loader}
        {successRequest && (
          <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
        )}
      </button>
    </form>
  )
}

export default FooterForm
