import { useState } from "react"

import axios from "axios"
import { Form, Formik } from "formik"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

import Container from "../../UI/Container"

import Input from "../formComponents/Input"
import Select from "../formComponents/Select"
import RadioInput from "../formComponents/RadioInput"
import TextInput from "../formComponents/TextInput"
import Button from "../../UI/Button"

import { trainerSchema } from "../formSchema/trainerSchema"

const loader = (
  <svg
    className="inline-block ml-3"
    width="20"
    height="20"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="3">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
)

const initialValues = {
  fullname: "",
  email: "",
  is_usthb_student: "",
  study_level: "",
  study_field: "",
  projects: "",
  phone: "",
  course_title: "",
  course_description: "",
  linked_in: "",
}

let errorMessage = ""

const addTrainerRequestUrl = "https://api.celec.club/api/trainer/create"

const formData = new FormData()

const handleCvInput = (event) => {
  formData.append("cv", event.target.files[0], event.target.files[0].name)
}

const TrainerForm = () => {
  const [successRequest, setSuccessRequest] = useState(false)

  const submitFormHandler = (values, { resetForm }) => {
    setSuccessRequest(false)

    errorMessage = ""

    function submitMemberData(memberValues) {
      formData.append("fullname", memberValues.fullname)
      formData.append("email", memberValues.email)
      formData.append("is_usthb_student", memberValues.is_usthb_student)
      formData.append("study_level", memberValues.study_level)
      formData.append("study_field", memberValues.study_field)
      formData.append("projects", memberValues.projects)
      formData.append("phone", memberValues.phone)
      formData.append("course_title", memberValues.course_title)
      formData.append("course_description", memberValues.course_description)
      formData.append("linked_in", memberValues.linked_in)

      axios
        .post(addTrainerRequestUrl, formData)
        .then(function (response) {
          console.log(response)
          setSuccessRequest(true)

          resetForm({ values: "" })
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            const data = error.response.data
            const errors = data.errors
            for (const [key, value] of Object.entries(errors)) {
              errorMessage += `${key}: ${value}`
              errorMessage += "\n"
            }
            alert(errorMessage)
            console.log(error)
          } else {
            // errorMessage = "Server error please try again"
            alert("Server error please try again")
          }
        })
    }
    submitMemberData(values)
  }

  return (
    <Container className="my-[100px]">
      <Formik
        initialValues={initialValues}
        validationSchema={trainerSchema}
        onSubmit={submitFormHandler}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-2 justify-center relative ph:grid-cols-1">
            <div className="justify-self-center">
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                name="fullname"
              />
              <Input id="email" label="Email" type="email" name="email" />
              <p className="text-Color-Blue ml-8 mb-3">
                Are you an USTHB Student
              </p>
              <div>
                <RadioInput
                  id="is_usthb_student-true"
                  label="Yes"
                  name="is_usthb_student"
                  value="yes"
                />
                <RadioInput
                  id="is_usthb_student-false"
                  label="No"
                  name="is_usthb_student"
                  value="no"
                />
              </div>
              <Select
                id="study_level"
                label="Study Level"
                type="text"
                name="study_level"
              >
                <option value="">Please select your study level</option>
                <option value="L1">L1</option>
                <option value="L2">L2</option>
                <option value="L3">L3</option>
                <option value="M1">M1</option>
                <option value="M2">M2</option>
                <option value="phd">Doctorate</option>
              </Select>
              <Input
                id="study_field"
                label="Study Field"
                type="text"
                name="study_field"
              />
              <TextInput
                id="projects"
                label="Your projects with a brief description"
                name="projects"
              />
            </div>
            <div className="justify-self-center">
              <Input
                id="phone"
                label="Phone number"
                type="number"
                name="phone"
                min="0"
              />
              <Input
                id="course_title"
                label="Course Title"
                type="text"
                name="course_title"
              />
              <TextInput
                id="course_description"
                label="Course Description"
                name="course_description"
              />
              <Input
                id="cv"
                label="Upload your CV"
                type="file"
                accept="application/pdf"
                name="cv"
                required
                onChange={handleCvInput}
              />
              <Input
                id="linked_in"
                label="Your LinkedIn profile"
                type="text"
                name="linked_in"
              />
              <Button
                type="submit"
                className="w-80 text-white bg-Color-Blue py-3 rounded-lg mt-2"
              >
                Send
                {isSubmitting && loader}
                {successRequest && (
                  <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default TrainerForm
