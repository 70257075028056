import { useContext, useState, useEffect, useRef } from "react"

import { Link } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"

import { MeiliSearch } from "meilisearch"

import Container from "../UI/Container"
import ContentBox from "../UI/ContentBox"
// import Search from "./Search"
// import Tags from "./Tags"
import Pagination from "../UI/Pagination"
import TrainerLink from "./TrainerLink"

import CoursesContext from "../../utils/courses-context"

const client = new MeiliSearch({
  host: "102.220.29.6:7770",
  apiKey:
    "EOTZ7r3n41b15e375e11c48d9f09846d1fe86cb0dbaad2b6d3e3699e4463ea7b80c29b8c",
})

const clientIndex = client.index("trainings")

let searchedTrainings = []

const Courses = () => {
  const CoursesCtx = useContext(CoursesContext)

  const [trainings, setTrainings] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const postsPerPage = 9
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage

  const searchRef = useRef()

  const onChangeSearchHandler = () => {
    searchedTrainings = []

    if (searchRef.current.value === "") {
      setTrainings(CoursesCtx.courses)
    } else {
      clientIndex.search(searchRef.current.value).then((res) => {
        const data = res.hits

        for (const key in data) {
          searchedTrainings.push({
            title: data[key].title,
            description: data[key].description,
            imgSrc: `https://api.celec.club/storage/${data[key].cover}`,
            slug: data[key].slug,
            isClosed: data[key].is_closed,
          })
        }

        setTrainings(searchedTrainings)
      })
    }
  }

  useEffect(() => {
    if (searchRef.current.value === "") {
      setTrainings(CoursesCtx.courses)
    }
  }, [CoursesCtx.courses])

  const currentPosts = trainings.slice(firstPostIndex, lastPostIndex)

  return (
    <Container className="min-h-screen" id="trainings">
      {/* Search Container */}
      <div className="mt-5 relative w-full">
        <input
          onChange={onChangeSearchHandler}
          ref={searchRef}
          type="text"
          id="search"
          name="search"
          placeholder=" "
          className="search-input w-full px-5 py-3 rounded-[25px] bg-[#fffefe] border-0 outline-0 shadow-md shadow-[Color-Gray]"
        />
        <label
          htmlFor="search"
          className="absolute top-[50%] translate-y-[-50%] left-5"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2.5" />
          Search
        </label>
      </div>
      {/* <Tags /> */}
      {/* Courses Container */}
      <div className="flex flex-wrap justify-center gap-5 py-[4rem]">
        {currentPosts.map((training) => (
          <ContentBox
            key={training.slug}
            className="flex flex-col align-center justify-between text-center bg-[#fafafa] w-[350px] h-[580px] rounded-lg border-[1px] border-solid border-[#788092]"
            src={training.imgSrc}
            imgClasses="h-28 mx-auto mb-4"
            heading={training.title}
            headingClasses="text-lg capitalize font-bold text-Color-Dark-Blue mb-4"
            paragraph={training.description}
            parClasses="text-sm "
          >
            <Link
              to={`/training/${training.id}`}
              className={
                training.isClosed || training.isClosed === 0
                  ? "bg-black text-white py-2 px-4 mt-3 rounded-lg disabled"
                  : "bg-Color-Blue text-white py-2 px-4 mt-3 rounded-lg"
              }
            >
              {training.isClosed || training.isClosed === 0
                ? "Registration are closed"
                : "Register"}
            </Link>
          </ContentBox>
        ))}
      </div>
      <Pagination
        totalPosts={trainings.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <TrainerLink />
    </Container>
  )
}

export default Courses
