import { useState, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons"
// faAngleDown
import CelecLogo from "../../assets/logos/Celec_Logo_Blue.svg"

import Container from "../../components/UI/Container"
import NavbarLink from "./NavbarLink"
import NavbarHashLink from "./NavbarHashLink"

const ulStyle = "flex justify-between items-center text-center  "
const ulTabletStyle =
  "flex-col absolute top-[100%] left-0 w-[100%] items-start p-2.5 bg-[rgba(0,0,0,.3)] z-10"

const Header = () => {
  const [isClicked, setIsClicked] = useState(false)
  const [windowIsOnTabletSize, setWindowIsOnTabletSize] = useState(null)
  const [showRegistrationLinks, setShowRegistrationLinks] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 992) {
      setWindowIsOnTabletSize(true)
    } else {
      setWindowIsOnTabletSize(false)
    }

    window.onresize = () => {
      if (window.innerWidth < 992) {
        setWindowIsOnTabletSize(true)
      } else {
        setWindowIsOnTabletSize(false)
      }
    }
  }, [windowIsOnTabletSize])

  const showNavBarHandler = () => {
    setIsClicked(!isClicked)
  }

  return (
    <header className="relative">
      <Container className="">
        <ul
          className={
            !windowIsOnTabletSize
              ? ulStyle
              : isClicked
              ? ulTabletStyle
              : "hidden"
          }
        >
          <NavbarLink to="/home" onClick={() => setIsClicked(false)}>
            Home
          </NavbarLink>

          <NavbarHashLink to="/home#about" onClick={() => setIsClicked(false)}>
            About Us
          </NavbarHashLink>

          <NavbarHashLink
            to="/home#activities"
            onClick={() => setIsClicked(false)}
          >
            Our Activities
          </NavbarHashLink>

          <Link to="/home" className="tab:hidden">
            <img className="w-20" src={CelecLogo} alt="Celec Logo" />
          </Link>

          <li
            className="relative cursor-pointer text-lg font-bold uppercase tab:mb-3"
            onClick={() => setShowRegistrationLinks(!showRegistrationLinks)}
          >
            Registration
            <FontAwesomeIcon icon={faChevronDown} className="text-lg ml-2" />
            {showRegistrationLinks && (
              <div className="absolute top-[100%] w-[100%] bg-white text-start font-normal text-sm capitalize tab:relative tab:bg-transparent  duration-1000">
		  {<li className="hover:text-white hover:bg-Color-Blue px-2.5 py-1">
                  <a
                    href="https://join.celec.club"
                    className={(navData) =>
                      navData.isActive
                        ? "w-[100%] bg-Color-Blue text-white inline-block px-2 py-1 "
                        : "inline-block px-2 py-1 "
                    }
                  >
                    Join The Team
                  </a>
                </li>}
                <li className="hover:text-white hover:bg-Color-Blue">
                  <NavLink
                    to="/training"
                    className={(navData) =>
                      navData.isActive
                        ? "w-[100%] bg-Color-Blue text-white inline-block px-2.5 py-1 "
                        : "inline-block px-2.5 py-1 "
                    }
                  >
                    Training
                  </NavLink>
                </li>
                <li className="hover:text-white hover:bg-Color-Blue">
                  <NavLink
                    to="/event"
                    className={(navData) =>
                      navData.isActive
                        ? "w-[100%] bg-Color-Blue text-white inline-block px-2.5 py-1 "
                        : "inline-block px-2.5 py-1 "
                    }
                  >
                    Our Events
                  </NavLink>
                </li>
              </div>
            )}
          </li>

          <NavbarHashLink to="/home#blog" onClick={() => setIsClicked(false)}>
            Blog
          </NavbarHashLink>

          <NavbarHashLink to="#footer" onClick={() => setIsClicked(false)}>
            Contact Us
          </NavbarHashLink>
        </ul>

        <FontAwesomeIcon
          icon={faBars}
          className="text-lg absolute right-5 top-[24px] translate-y-[-50%] z-10 hidden tab:block"
          onClick={showNavBarHandler}
        />
      </Container>
    </header>
  )
}

export default Header
