import React from "react"

import Landing from "../components/Landing/Landing"
import ClubInfo from "../components/ClubInfo/ClubInfo"
import About from "../components/About/About"
import Activities from "../components/Activities/Activities"
import BlogSection from "../components/BlogSection/BlogSection"

import { BlogContextProvider } from "../utils/blog-context"

const Home = () => {
  return (
    <main>
      <Landing />
      <ClubInfo />
      <About />
      <Activities />
      <BlogContextProvider>
        <BlogSection />
      </BlogContextProvider>
    </main>
  )
}

export default Home
