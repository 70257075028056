import React from "react"

import PagesLanding from "../components/UI/PagesLanding"
import TrainerForm from "../components/forms/TrainerForm/TrainerForm"

import teacher from "../assets/icons/undraw_teacher_re_sico.svg"

const Trainer = () => {
  return (
    <div>
      <PagesLanding
        landingHeading="REGISTER NOW TO BE A TRAINER"
        // landingText="learn a skill for your future, thanks to our training"
      >
        <img src={teacher} alt="" className="w-36" />
      </PagesLanding>
      <TrainerForm />
    </div>
  )
}

export default Trainer
