import React from "react"
import { Link } from "react-router-dom"

import teacher from "../../assets/icons/undraw_teacher_re_sico.svg"

const TrainerLink = () => {
  return (
    <div className="my-10">
      <h2 className="mb-10 text-3xl text-center text-Color-Dark-Blue font-bold capitalize">
        Ready to give your career a new lease on life?
      </h2>
      <div className="flex justify-center flex-wrap items-center gap-x-28 ph:text-center">
        <img src={teacher} alt="" className="w-52" />
        <div className="relative">
          <h3 className="max-w-xs text-xl ph:text-center">
            Do you have skills in certain field? Are you ready to train
            students?
          </h3>
          <Link
            to="/trainer"
            className="inline-block py-2 px-4 mt-4 bg-Color-Blue text-white rounded-md"
          >
            Register Now
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TrainerLink
