import React from "react"

import CourseRegistration from "../components/Courses/CourseRegistration"
import { CoursesContextProvider } from "../utils/courses-context"

const SingleCourse = () => {
  return (
    <div>
      <CoursesContextProvider>
        <CourseRegistration />
      </CoursesContextProvider>
    </div>
  )
}

export default SingleCourse
