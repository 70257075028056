import React from "react"
import { HashLink } from "react-router-hash-link"

const NavbarHashLink = (props) => {
  return (
    <li className="tab:mb-5" onClick={props.onClick}>
      <HashLink smooth to={props.to} className="text-lg font-bold uppercase ">
        {props.children}
      </HashLink>
    </li>
  )
}

export default NavbarHashLink
