import React from "react"
import { NavHashLink } from "react-router-hash-link"

import PagesLanding from "../components/UI/PagesLanding"
import CelecEvent from "../components/CelecEvent/CelecEvent"

import { EventsContextProvider } from "../utils/event-context"

const Events = () => {
  return (
    <div>
      <PagesLanding
        landingHeading="Events"
        landingText="A place for discovery, networking and opportunities"
      >
        <NavHashLink
          to="/event#events"
          className="bg-Color-Blue text-white text-lg uppercase p-4 my-10 rounded-lg"
        >
          View our events
        </NavHashLink>
      </PagesLanding>
      <EventsContextProvider>
        <CelecEvent />
      </EventsContextProvider>
    </div>
  )
}

export default Events
