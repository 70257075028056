import React from "react"

const BtnClasses =
  "mx-2 bg-white text-Color-Blue font-bold w-8 h-8 border-[1px] border-solid border-Color-Blue rounded-[50%] hover:bg-Color-Blue hover:text-white"

const Pagination = (props) => {
  const pages = []

  for (let i = 1; i <= Math.ceil(props.totalPosts / props.postsPerPage); i++)
    pages.push(i)
  return (
    <div className="text-center">
      {pages.map((page, index) => (
        <button
          key={index}
          className={
            page === props.currentPage
              ? `mx-2 font-bold w-8 h-8 border-[1px] border-solid border-Color-Blue rounded-[50%] bg-Color-Blue text-white`
              : BtnClasses
          }
          onClick={() => props.setCurrentPage(page)}
        >
          {page}
        </button>
      ))}
    </div>
  )
}

export default Pagination
