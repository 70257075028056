import { useContext, useState, useEffect } from "react"

import { useParams } from "react-router-dom"

import ContentBox from "../UI/ContentBox"
import EventForm from "../forms/EventForm/EventForm"

import EventsContext from "../../utils/event-context"

const CourseRegistration = () => {
  const { eventId } = useParams()

  const EventsCtx = useContext(EventsContext)

  const [registerToEvent, setRegisterToEvent] = useState({
    title: "",
    description: "",
    imgSrc: "",
    isClosed: "",
  })
  const [disableBtn, setDisableBtn] = useState(false)

  useEffect(() => {
    if (EventsCtx.events.length === 0) {
      setRegisterToEvent({
        title: "",
        description: "",
        imgSrc: "",
        isClosed: "",
      })
    } else {
      const targetEvent = EventsCtx.events.find(
        (event) => event.id === +eventId
      )

      console.log(targetEvent)

      setRegisterToEvent(targetEvent)
      setDisableBtn(registerToEvent.isClosed)
    }
  }, [EventsCtx, EventsCtx.events, eventId, registerToEvent.isClosed])

  const { title, description, imgSrc, isClosed } = registerToEvent

  return (
    <div className="">
      <ContentBox
        className="h-[calc(100vh-80px)] tab:h-[100vh] flex flex-col items-center justify-center text-center"
        src={imgSrc}
        imgClasses="w-80 my-4"
        heading={title}
        headingClasses="text-lg capitalize font-bold text-Color-Dark-Blue my-4"
        paragraph={description}
        parClasses="max-w-xl my-4"
      />
      <EventForm eventId={eventId} closed={isClosed} disableBtn={disableBtn} />
    </div>
  )
}

export default CourseRegistration
