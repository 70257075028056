import React from "react"
import { NavLink } from "react-router-dom"

const NavbarLink = (props) => {
  return (
    <li className="tab:mb-5 relative" onClick={props.onClick}>
      <NavLink
        to={props.to}
        className={(navData) =>
          navData.isActive
            ? "active-link text-lg font-bold uppercase "
            : "text-lg font-bold uppercase "
        }
      >
        {props.children}
      </NavLink>
    </li>
  )
}

export default NavbarLink
