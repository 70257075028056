import { useState } from "react"

import axios from "axios"
import { Form, Formik } from "formik"
import { formSchema } from "../formSchema/formSchema"

import Container from "../../UI/Container"
import Input from "../formComponents/Input"
import RadioInput from "../formComponents/RadioInput"
import Select from "../formComponents/Select"
import TextInput from "../formComponents/TextInput"
import Button from "../../UI/Button"
import FormPortal from "../FormProtal/FormPortal"

const loader = (
  <svg
    className="inline-block ml-3"
    width="20"
    height="20"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="3">
        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
)

const initialValues = {
  fullname: "",
  email: "",
  is_usthb_student: "",
  registration_number: "",
  study_level: "",
  study_field: "",
  projects: "",
  intersted_in: "",
  skills: "",
  other_clubs_experience: "",
  linked_in: "",
  motivation: "",
  birthdate: "",
}

let errorMessage = ""

const RegisterForm = () => {
  const [showPortal, setShowPortal] = useState(false)
  const [formIsNotValid, setFormIsNotValid] = useState(false)
  const [successRequest, setSuccessRequest] = useState(false)

  const closePortalHandler = () => {
    setShowPortal(false)
    setFormIsNotValid(false)
    setSuccessRequest(false)
  }

  const createMemberRequestUrl = "https://api.celec.club/api/member/create"

  const formData = new FormData()

  const handleImageInput = (event) => {
    formData.append("image", event.target.files[0], event.target.files[0].name)
  }

  const handleCvInput = (event) => {
    formData.append("cv", event.target.files[0], event.target.files[0].name)
  }

  const submitFormHandler = (values, { resetForm }) => {
    closePortalHandler()
    errorMessage = ""

    function submitMemberData(memberValues) {
      formData.append("fullname", memberValues.fullname)
      formData.append("email", memberValues.email)
      formData.append("is_usthb_student", memberValues.is_usthb_student)
      formData.append("registration_number", memberValues.registration_number)
      formData.append("study_level", memberValues.study_level)
      formData.append("study_field", memberValues.study_field)
      formData.append("projects", memberValues.projects)
      formData.append("intersted_in", memberValues.intersted_in)
      formData.append("skills", memberValues.skills)
      formData.append(
        "other_clubs_experience",
        memberValues.other_clubs_experience
      )
      formData.append("linked_in", memberValues.linked_in)
      formData.append("motivation", memberValues.motivation)
      formData.append("birthdate", memberValues.birthdate)

      axios
        .post(createMemberRequestUrl, formData)
        .then(function (response) {
          // const fullName = response.data.fullname
          // alert("Congratulations " + fullName)
          setSuccessRequest(true)
          setShowPortal(true)
          resetForm({ values: "" })
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            const data = error.response.data
            const errors = data.errors
            for (const [key, value] of Object.entries(errors)) {
              errorMessage += `${key}: ${value}`
              errorMessage += "\n"
            }
            // alert(errorMessage)
            setFormIsNotValid(true)
            setShowPortal(true)
          } else {
            // errorMessage = "Server error please try again"
            alert("Server error please try again")
          }
        })
    }
    submitMemberData(values)
  }

  return (
    <Container className="py-[100px]">
      <FormPortal
        isOpened={showPortal}
        formValidity={formIsNotValid}
        errorMessage={errorMessage}
        success={successRequest}
        closePortal={closePortalHandler}
      ></FormPortal>
      {/* <h3 className="mb-10 text-center text-2xl text-[#e51c28] font-bold uppercase">
        Registrations are closed 
      </h3> */}
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={submitFormHandler}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-2 justify-center relative ph:grid-cols-1">
            <div className="justify-self-center">
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                name="fullname"
              />
              <Input id="email" label="Email" type="email" name="email" />
              <Input
                id="birthdate"
                label="Your birth date"
                type="date"
                name="birthdate"
              />
              <p className="text-Color-Blue ml-8 mb-3">
                Are you an USTHB Student
              </p>
              <div>
                <RadioInput
                  id="usthb-student-true"
                  label="Yes"
                  name="is_usthb_student"
                  value="yes"
                />
                <RadioInput
                  id="usthb-student-false"
                  label="No"
                  name="is_usthb_student"
                  value="no"
                />
              </div>
              <Input
                id="registration_number"
                label="Badge Number"
                type="number"
                name="registration_number"
                min="0"
              />
              <Select
                id="study_level"
                label="Study Level"
                type="text"
                name="study_level"
              >
                <option value="">Please select your study level</option>
                <option value="L1">L1</option>
                <option value="L2">L2</option>
                <option value="L3">L3</option>
                <option value="M1">M1</option>
                <option value="M2">M2</option>
                <option value="phd">Doctorate</option>
              </Select>
              <Input
                id="study_field"
                label="Study Field"
                type="text"
                name="study_field"
              />
              <TextInput
                id="projects"
                label="Your projects with a brief description"
                name="projects"
              />
            </div>
            <div className="justify-self-center">
              <Input
                id="intersted_in"
                label="You are interested in what field?"
                type="text"
                name="intersted_in"
              />
              <Input
                id="skills"
                label="List your skills"
                type="text"
                name="skills"
              />
              <TextInput
                id="other_clubs_experience"
                label="Do you already have experience with scientific clubs? If yes list them"
                name="other_clubs_experience"
              />
              <Input
                id="cv"
                label="Upload your CV (Not required)"
                type="file"
                accept="application/pdf"
                name="cv"
                onChange={handleCvInput}
              />
              <Input
                id="image"
                label="Upload your identity image (to generate your Celec Member Badge)"
                type="file"
                accept="image/*"
                name="image"
                required
                onChange={handleImageInput}
              />
              <Input
                id="linked_in"
                label="Your LinkedIn profile (Not required)"
                type="text"
                name="linked_in"
              />
              <TextInput
                id="motivation"
                label="Your motivation to join CELEC"
                name="motivation"
              />
              <Button
                type="submit"
                className="w-80 text-white bg-Color-Blue py-3 rounded-lg mt-2"
              >
                Submit
                {isSubmitting && loader}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default RegisterForm
