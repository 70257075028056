import * as yup from "yup"

export const trainerSchema = yup.object().shape({
  fullname: yup.string().required(),

  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter you email"),

  is_usthb_student: yup.string().required().oneOf(["yes", "no"]),

  study_level: yup
    .string()
    .oneOf(["L1", "L2", "L3", "M1", "M2", "phd"], "Invalid level")
    .required("Required"),

  study_field: yup.string().required(),

  projects: yup.string().required(),

  phone: yup.number().positive().integer().required(),

  course_title: yup.string().required(),

  course_description: yup.string().required(),

  linked_in: yup.string().required(),
})
