import * as yup from "yup"

const stringTypeYup = yup.string().required()

export const formSchema = yup.object().shape({
  fullname: stringTypeYup,

  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter you email"),

  birthdate: yup
    .date()
    .required()
    .max(new Date(), "You can't be born in the future!"),

  is_usthb_student: yup.string().required().oneOf(["yes", "no"]),
  registration_number: yup.number().positive().integer().required("Required"),
  study_field: stringTypeYup,

  study_level: yup
    .string()
    .oneOf(["L1", "L2", "L3", "M1", "M2", "phd"], "Invalid level")
    .required("Required"),

  intersted_in: stringTypeYup,
  other_clubs_experience: stringTypeYup,
  linked_in: yup.string(),
  motivation: stringTypeYup,
})
