import React from "react"

// import Container from "./Container"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faFacebookF,
  faYoutube,
  faInstagram,
  faGithub,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

const SocialLink = (props) => {
  return (
    <a
      href={props.href}
      className="inline-block bg-Color-Blue w-8 h-8 rounded-[50%] relative mr-2"
    >
      <FontAwesomeIcon
        icon={props.icon}
        className="text-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
      />
    </a>
  )
}

const SocialMedia = () => {
  return (
    <div className="">
      <SocialLink href="https://lc.cx/WOfYqo" icon={faFacebookF} />
      <SocialLink href="https://lc.cx/-p_J9C" icon={faYoutube} />
      <SocialLink href="https://lc.cx/bJh2Au" icon={faInstagram} />
      <SocialLink href="https://lc.cx/HYHnbs" icon={faGithub} />
      <SocialLink href="https://lc.cx/EocH5u" icon={faLinkedinIn} />
    </div>
  )
}

export default SocialMedia
