import React from "react"

import graduated from "../../assets/icons/graduated.png"
import innovation from "../../assets/icons/innovation.png"
import graduatedCap from "../../assets/icons/diplome-universitaire.png"

const Banner = (props) => {
  return (
    <div
      className={`w-[100%] flex justify-between px-20 py-4 bg-Color-Black flex-wrap tab:text-center tab:justify-center ${props.className}`}
    >
      <div className="flex tab:px-6">
        <img src={graduated} alt=" " className="h-16 mr-3 tab:py-2 ph:h-12" />
        <h3 className="text-white text-lg font-bold self-center ph:text-xs">
          Skilled People
        </h3>
      </div>
      <div className="flex tab:px-6">
        <img src={innovation} alt=" " className="h-16 mr-3 tab:py-2 ph:h-12" />
        <h3 className="text-white text-lg font-bold self-center ph:text-xs">
          Innovative Projects
        </h3>
      </div>
      <div className="flex tab:px-3">
        <img
          src={graduatedCap}
          alt=" "
          className="h-16 mr-3 tab:py-2 ph:h-12"
        />
        <h3 className="text-white text-lg font-bold self-center ph:text-xs">
          Trainings
        </h3>
      </div>
    </div>
  )
}

export default Banner
