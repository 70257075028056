import { useContext } from "react"

import Slider from "react-slick"
import BlogContext from "../../utils/blog-context"

const BlogSection = () => {
  const BlogCtx = useContext(BlogContext)

  const blogTitles = BlogCtx.blogData.map((title) => (
    <h4
      key={title.id}
      className="text-xs bg-white mb-1 py-1 px-1.5 rounded-[5px]"
    >
      <a href={title.link} target="_blank" rel="noreferrer">
        {title.content}
      </a>
    </h4>
  ))

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    autoplay: true,
    autoplaySpeed: 1,
  }
  return (
    <div
      id="blog"
      className="relative flex justify-around flex-wrap z-10 p-16 blog"
    >
      <div className="max-w-[400px] self-center">
        <h2 className="text-3xl font-bold uppercase text-Color-Dark-Blue mb-5">
          Blog Section
        </h2>
        {/* <p className="text-sm text-black">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
          dolore mollitia cum velit quo, cumque voluptas odio neque, corporis
          facilis distinctio quam optio perferendis obcaecati, quisquam
          quibusdam dicta natus! Dolor!
        </p> */}
      </div>
      <div className="flex justify-end ph:flex-col gap-y-3 border-[1px] border-[#aaa] border-solid rounded w-[470px]">
        <div className="self-center mr-8 ph:ml-4 ph:py-4">
          <h3 className="text-2xl text-Color-Blue uppercase font-bold mb-1">
            Blog
          </h3>
          {/* <p className="max-w-[150px] text-xs mb-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p> */}
          <a
            href="https://blog.celec.club/"
            className="text-xs font-bold text-white bg-Color-Blue px-4 py-2 rounded"
          >
            View Our Blog
          </a>
        </div>
        <div className="bg-Color-Dark-Blue p-4 rounded-md h-[200px] w-[280px] overflow-hidden">
          <Slider {...settings}>{blogTitles}</Slider>
        </div>
      </div>
    </div>
  )
}

export default BlogSection
