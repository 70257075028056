import { useContext, useState, useEffect } from "react"

import { Link } from "react-router-dom"

import Container from "../UI/Container"
import ContentBox from "../UI/ContentBox"

import Pagination from "../UI/Pagination"

import EventsContext from "../../utils/event-context"

const CelecEvent = () => {
  const EventsCtx = useContext(EventsContext)

  const [events, setEvents] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const postsPerPage = 9
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage

  useEffect(() => {
    const test = setTimeout(() => {
      setEvents(EventsCtx.events)
      clearTimeout(test)
    }, "300")
  }, [EventsCtx.events])

  const currentPosts = events.slice(firstPostIndex, lastPostIndex)

  return (
    <Container className="min-h-screen" id="events">
      <div className="flex flex-wrap justify-center gap-5 py-[4rem]">
        {currentPosts.map((event) => (
          <ContentBox
            key={event.id}
            className="flex flex-col align-center justify-between text-center bg-[#fafafa] w-[350px] h-[580px] rounded-lg border-[1px] border-solid border-[#788092]"
            src={event.imgSrc}
            imgClasses="h-28 mx-auto mb-4"
            heading={event.title}
            headingClasses="text-lg capitalize font-bold text-Color-Dark-Blue mb-4"
            paragraph={event.description}
            parClasses="text-sm "
          >
            <Link
              to={`/event/${event.id}`}
              className={
                event.isClosed || event.isClosed === 0
                  ? "bg-black text-white py-2 px-4 mt-3 rounded-lg disabled"
                  : "bg-Color-Blue text-white py-2 px-4 mt-3 rounded-lg"
              }
            >
              {event.isClosed || event.isClosed === 0
                ? "Registration are closed"
                : "Register"}
            </Link>
          </ContentBox>
        ))}
      </div>

      <Pagination
        totalPosts={events.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </Container>
  )
}

export default CelecEvent
