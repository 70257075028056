import React, { Component } from "react"
import Slider from "react-slick"

import Container from "../UI/Container"
import ContentBox from "../UI/ContentBox"

import educationalOutingIcon from "../../assets/icons/travel-map.png"
import projectsIcon from "../../assets/icons/project-management.png"
import trainingsIcon from "../../assets/icons/teaching.png"
// import conferenceIcon from "../../assets/icons/conference.png"
import competitionIcon from "../../assets/icons/handshake.png"

const cards = [
  {
    src: educationalOutingIcon,
    heading: "Educational Outing",
    paragraph:
      "As a student, many individuals feel that they lack professional experience, so CELEC organizes field trips to industries to allow students to become familiar with the working world.",
  },
  {
    src: projectsIcon,
    heading: "Project",
    paragraph:
      "Electronics, Robotics, Digital or Design, the CELEC teams work hard to create innovative and creative projects. ",
  },
  {
    src: trainingsIcon,
    heading: "Training",
    paragraph:
      "For those in need, CELEC offers a wide range of training courses to help you get started and set your goals in different areas. ",
  },
  // {
  //   src: conferenceIcon,
  //   heading: "Conference",
  //   paragraph: "Lorem ipsum dolor sit Lorem ipsum dolor sit",
  // },
  {
    src: competitionIcon,
    heading: "Competition",
    paragraph:
      "Whether on a national or international level, CELEC and its teams are regular participants in various competitions. ",
  },
]

const imgClasses = "w-28 mt-5 mb-7 mx-3"
const headingClasses =
  "uppercase text-white bg-Color-Blue rounded-md px-3 py-1  inline-block"
const parClasses = "text-sm mt-5 max-w-[300px]"

const activity = cards.map((card, cardIndex) => (
  <ContentBox
    className="min-w-[16.875rem] h-96 bg-Color-Gray mx-2 my-3 p-5 rounded-md border-2 border-[#788092] border-solid hover:bg-white hover:scale-105 relative duration-1000"
    key={cardIndex}
    src={card.src}
    imgClasses={imgClasses}
    heading={card.heading}
    headingClasses={headingClasses}
    paragraph={card.paragraph}
    parClasses={parClasses}
  />
))

export default class Activities extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <Container id="activities" className="my-20">
        <div>
          <h2 className="text-5xl font-bold uppercase text-Color-Dark-Blue mb-5">
            Our Activities
          </h2>
          {/* <p className="text-xl text-black leading-9">
            Lorem ipsum dolor sit Lorem ipsum dolor sit Lorem ipsum dolor sit
          </p> */}
        </div>
        <div>
          <Slider {...settings}>{activity}</Slider>
        </div>
      </Container>
    )
  }
}
