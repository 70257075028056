import * as yup from "yup"

export const eventSchema = yup.object().shape({
  firstname: yup.string().required("Your first name is required"),

  lastname: yup.string().required("Your last name is required"),

  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),

  phone_number: yup
    .number()
    .positive()
    .integer()
    .required("Your phone number is required"),

  id_card_number: yup
    .number()
    .positive()
    .integer()
    .required("Your Id number is required"),

  are_you_student: yup
    .string()
    .required("Please answer the question")
    .oneOf(["yes", "no"]),

  motivation: yup.string().required("Your motivation is required"),

  study_field: yup.string().required("Your study field is required"),

  fonction: yup.string(),

  is_usthb: yup
    .string()
    .required("Please answer the question")
    .oneOf(["yes", "no"]),
})
