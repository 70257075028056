import React from "react"

import Container from "../UI/Container"

import aboutIcon from "../../assets/icons/information (1).png"

const About = () => {
  return (
    <Container
      id="about"
      className="grid grid-cols-2 ph:grid-cols-1 justify-center items-center my-[6.25rem]"
    >
      <div className="justify-self-center ph:mb-10 relative z-10 light-circle  ">
        <img src={aboutIcon} alt="about" className="w-[250px] tab:w-[200px]" />
      </div>
      <div className="ph:text-center">
        <h2 className="text-5xl font-bold uppercase text-Color-Dark-Blue mb-5">
          About Us
        </h2>
        <p className="text-lg text-black leading-9 tab:text-sm">
          CELEC is a scientific club created in 1987 by students of the faculty
          of electronics and computer science (currently Electrical Engineering)
          of the USTHB in order to exchange their knowledge and their skills in
          the field of electronics. Now CELEC club has evolved, and has many
          axes, including the realization of projects, participation and
          organization of events and competitions, trainings for club members to
          acquire new skills and the organization of pedagogical outings in
          companies.
        </p>
      </div>
    </Container>
  )
}

export default About

// before:bg-[#c3cdf2] before:w-[100px] before:h-[100px]
