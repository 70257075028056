import { useState } from "react"

import axios from "axios"
import { Form, Formik } from "formik"

import { eventSchema } from "../formSchema/eventSchema"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

import Container from "../../UI/Container"
import Input from "../formComponents/Input"
import RadioInput from "../formComponents/RadioInput"
import TextInput from "../formComponents/TextInput"
import Button from "../../UI/Button"
import FormPortal from "../FormProtal/FormPortal"

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone_number: "",
  id_card_number: "",
  are_you_student: "",
  motivation: "",
  study_field: "",
  fonction: "",
  is_usthb: "",
}

let errorMessage = ""

const EventForm = (props) => {
  const [successRequest, setSuccessRequest] = useState(false)
  const [showPortal, setShowPortal] = useState(false)
  const [formIsNotValid, setFormIsNotValid] = useState(false)

  const closePortalHandler = () => {
    setShowPortal(false)
    setFormIsNotValid(false)
  }

  const registerToEventRequestURL = `https://api.celec.club/api/events/${props.eventId}/register`

  const formData = new FormData()

  const submitFormHandler = (values, { resetForm }) => {
    closePortalHandler()
    setSuccessRequest(false)

    errorMessage = ""

    function submitMemberData(memberValues) {
      formData.append("firstname", memberValues.firstname)
      formData.append("lastname", memberValues.lastname)
      formData.append("email", memberValues.email)
      formData.append("phone_number", memberValues.phone_number)
      formData.append("id_card_number", memberValues.id_card_number)
      formData.append("are_you_student", memberValues.are_you_student)
      formData.append("motivation", memberValues.motivation)
      formData.append("study_field", memberValues.study_field)
      formData.append("fonction", memberValues.fonction)
      formData.append("is_usthb", memberValues.is_usthb)

      axios
        .post(registerToEventRequestURL, formData)
        .then(function (response) {
          // const fullName = response.data.fullname
          // alert("Congratulations " + fullName)
          setSuccessRequest(true)

          resetForm({ values: "" })
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            const data = error.response.data
            const errors = data.errors
            for (const [key, value] of Object.entries(errors)) {
              errorMessage += `${key}: ${value}`
              errorMessage += "\n"
            }
            // alert(errorMessage)
            setFormIsNotValid(true)
            setShowPortal(true)
          } else {
            // errorMessage = "Server error please try again"
            alert("Server error please try again")
          }
        })
    }
    submitMemberData(values)
  }

  return (
    <Container className="my-[100px]">
      <FormPortal
        isOpened={showPortal}
        formValidity={formIsNotValid}
        errorMessage={errorMessage}
        success={false}
        closePortal={closePortalHandler}
      ></FormPortal>
      <Formik
        initialValues={initialValues}
        validationSchema={eventSchema}
        onSubmit={submitFormHandler}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-2 justify-center relative ph:grid-cols-1">
            <div className="justify-self-center">
              <Input
                id="firstname"
                label="First name"
                type="text"
                name="firstname"
              />
              <Input
                id="lastname"
                label="Last name"
                type="text"
                name="lastname"
              />
              <Input id="email" label="Email" type="email" name="email" />
              <Input
                id="phone_number"
                label="Phone number"
                type="number"
                name="phone_number"
                min="0"
              />
              <Input
                id="id_card_number"
                label="Your card Id number"
                type="number"
                name="id_card_number"
                min="0"
              />
            </div>
            <div className="justify-self-center">
              <p className="text-Color-Blue ml-8 mb-3">Are you a student</p>
              <div>
                <RadioInput
                  id="are_you_student-true"
                  label="Yes"
                  name="are_you_student"
                  value="yes"
                />
                <RadioInput
                  id="are_you_student-false"
                  label="No"
                  name="are_you_student"
                  value="no"
                />
              </div>
              <p className="text-Color-Blue ml-8 mb-3">
                Are you an USTHB student
              </p>
              <div>
                <RadioInput
                  id="is_usthb-true"
                  label="Yes"
                  name="is_usthb"
                  value="yes"
                />
                <RadioInput
                  id="is_usthb-false"
                  label="No"
                  name="is_usthb"
                  value="no"
                />
              </div>
              <Input
                id="study_field"
                label="Study Field"
                type="text"
                name="study_field"
              />
              <Input
                id="fonction"
                label="If no. Please write your function"
                type="text"
                name="fonction"
              />
              <TextInput id="motivation" label="Motivation" name="motivation" />
              <Button
                type="submit"
                // className="w-80 text-white bg-Color-Blue py-3 rounded-lg mt-2"
                disabled={props.disableBtn}
                className={
                  props.closed
                    ? "w-80 text-white bg-black py-3 rounded-lg mt-2"
                    : "w-80 text-white bg-Color-Blue py-3 rounded-lg mt-2"
                }
              >
                {props.closed ? "Registration are closed" : "Submit"}
                {successRequest && (
                  <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default EventForm
