import { useContext, useState, useEffect } from "react"

import { useParams } from "react-router-dom"

import ContentBox from "../UI/ContentBox"
import TrainingForm from "../forms/TrainingForm/TrainingForm"

import CoursesContext from "../../utils/courses-context"

const CourseRegistration = () => {
  const { courseId } = useParams()

  const CoursesCtx = useContext(CoursesContext)

  const [registerToCourse, setRegisterToCourse] = useState({
    id: "",
    title: "",
    description: "",
    imgSrc: "",
    isClosed: "",
  })
  const [disableBtn, setDisableBtn] = useState(false)

  useEffect(() => {
    const targetCourse = CoursesCtx.courses.find(
      (training) => training.id === +courseId
    )

    if (targetCourse === undefined) {
      setRegisterToCourse({
        title: "",
        description: "",
        imgSrc: "",
        isClosed: "",
      })
    } else {
      setRegisterToCourse(targetCourse)
      setDisableBtn(registerToCourse.isClosed)
    }
  }, [CoursesCtx.courses, courseId, registerToCourse.isClosed])

  const { title, description, imgSrc, isClosed } = registerToCourse

  return (
    <div className="">
      <ContentBox
        className="h-[calc(100vh-80px)] tab:h-[100vh] flex flex-col items-center text-center"
        src={imgSrc}
        imgClasses="w-80 my-4"
        heading={title}
        headingClasses="text-lg capitalize font-bold text-Color-Dark-Blue my-4"
        paragraph={description}
        parClasses="max-w-xl my-4"
      />
      <TrainingForm
        courseId={registerToCourse.id}
        closed={isClosed}
        disableBtn={disableBtn}
      />
    </div>
  )
}

export default CourseRegistration
